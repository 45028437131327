import React from "react"
import { Helmet } from "react-helmet"
import vector from "../images/Source_File_Mark1 1.png"
import { graphql } from "gatsby"
import img2 from "../images/Source_File_Mark 1 4.png"
import Layout from "../components/layout"
import Img from "gatsby-image"
import ContactSection from "../components/map"
import Footer from "../components/footer"

const data = [
  {
    img: vector,
    title: "Catering For All of Your Guests",
    content:
      "We know when you cater for others that might have special dietary needs it can be stressful, but just tell us when you order and our staff can guide you on which dishes will be suitable. Our menu already features vegetarian and vegan dishes, and some dishes can be specially prepared to be suitable for those with food allergies - without nuts for example.",
  },
  {
    img: vector,
    title: "Delivery",
    content:
      "“When will it be here?” We will let you know when order is on its way, so you can get on with greeting your guests, or delivering that important announcement.",
  },
  {
    img: vector,
    title: "Pricing",
    content:
      "Please contact us so we can give you the best quote for your event.",
  },
  {
    img: vector,
    title: "Catering for Larger Groups",
    content:
      "We are happy to cater for your larger events, but if you are booking for x15 or more people please book 7-10 days in advance, so that we can arrange all the ingredients for the dishes. We don't want to let you down.",
  },
]
const catering = probs => {
  return (
    <Layout>
      <Helmet>
        <title>Spice Brew | Welcome</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="If you’ve enjoyed our food already, or you are looking for something a little different to impress your office guests or friends at your party, Spice Brew can cater for office or venues within a 2 mile radius of our restaurants and cloud kitchens. You can pick the dishes you think they’ll like the most and we will bring them at scale."
        />
        <meta
          name="keywords"
          content="catering, london catering, indian food catering london, event management london, indian event food catering"
        />
        <link rel="canonical" href="https://spice-brew.com/catering" />
      </Helmet>

      <div className="overflow-x-hidden overflow-y-hidden">
        <section
          style={{
            background:
              "linear-gradient(99.67deg, #074E81 47.1%, rgba(7, 78, 129, 0.51) 131.38%)",
          }}
        >
          <div className="grid md:grid-cols-3 grid-cols-2">
            <div className="flex items-center justify-center">
              <div className="w-full">
                <Img
                  fluid={probs.data.coverImg.childImageSharp.fluid}
                  className=""
                />
              </div>
            </div>
            <div className="md:col-span-2 flex items-center">
              <img
                src={img2}
                alt=""
                className="z-10  h-14 sm:h-20 md:h-28 lg:h-36 2xl:h-56"
              />

              <h1
                className="2xl:text-6xl lg:text-5xl md:text-3xl sm:text-2xl text-xl text-green Moderat-Black sm:w-4/6 lg:w-3/6 md:w-3/6 w-auto leading-5	sm:leading-auto	"
                style={{
                  textShadow: " 0px 8.42389px 16.8478px rgba(0, 0, 0, 0.5)",
                }}
              >
                Let Spice Brew cater to you
              </h1>
            </div>
          </div>
        </section>

        <section className="flex flex-col items-center">
          <div className="py-10 px-5 md:px-10 mb-10 xl-max-w-1200 lg-max-w-1000">
            <p className="text-navyBlue xl:text-xl lg:text-lg sm:text-base text-sm Moderat-Regular mb-4">
              If you’ve enjoyed our food already, or you are looking for
              something a little different to impress your office guests or
              friends at your party, Spice Brew can cater for office or venues
              within a 2 mile radius of our restaurants and cloud kitchens. You
              can pick the dishes you think they’ll like the most and we will
              bring them at scale.
            </p>
            <p className="text-navyBlue xl:text-xl lg:text-lg sm:text-base text-sm Moderat-Regular">
              We price per head, but rather than individual dishes that make it
              awkward when someone unexpected turns up, we provide larger
              versions of the dishes that make it easy for your guests to dip in
              and out of. And it’ll never look like you forgot to cater for that
              unexpected person.
            </p>
            {data.map(datas => {
              return (
                <div className="flex flex-col items-start justify-start 2xl:py-5 lg:py-4 md:py-4 sm:py-4 py-5">
                  <div className="flex items-center">
                    <img
                      className="lg:w-12 md:w-8 sm:w-6 w-5 mr-1"
                      src={datas.img}
                      alt=""
                    />
                    <h2 className="text-black Moderat-bold lg:text-2xl  text-lg">
                      {datas.title}
                    </h2>
                  </div>
                  <h1 className="2xl:text-xl lg:text-lg md:text-base sm:text-sm text-sm text-normal text-navyBlue Moderat-Regular ml-2">
                    {datas.content}
                  </h1>
                </div>
              )
            })}
          </div>

          <div className="flex flex-col items-center">
            <button className="fancy">
              <span className="top-key"></span>
              <a
                href="https://spice-brew.square.site/order-catering"
                className="capitalize"
              >
                Book Catering
              </a>
              <span className="bottom-key-1"></span>
              <span className="bottom-key-2"></span>
            </button>
          </div>
        </section>
      </div>
      <ContactSection />
      <Footer />
    </Layout>
  )
}

export default catering
export const pageQuery = graphql`
  query {
    coverImg: file(relativePath: { eq: "Rectangle-33.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
